import React from "react";
import styles from "./styles.module.css";
import Button from "../../../components/Button";
import designer from "../../../../../assets/img/designer.jpg";

export default function ConceptionDeSitesWeb() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Services de conception de sites Web</h1>
        <img
          src={designer}
          alt="Femme, conception, sur, ordinateur portable"
          style={{ float: "right" }}
        ></img>
        <p>
          Un site bien conçu et convivial peut faire la différence entre 20 ou
          20 000 clients par mois. Chez NEBS, nous nous concentrons sur une
          chose : la convivialité. Nous vous aiderons à assurer à votre
          entreprise la présence Internet qui garantira sa réussite et, plus
          important encore, sa croissance en ligne. NEBS offre un vaste éventail
          de solutions de conception Web à des tarifs abordables. Que vous
          possédiez une petite entreprise et cherchiez à monter votre premier
          site Web ou exploitiez une grande société et désiriez étendre votre
          offre en ligne, nous avons pour vous une solution adaptée à vos
          besoins.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Confiez-nous la construction de votre site web</h2>
            <h3>Services de conception de site à partir de modèles</h3>
            <ul>
              <li>Choisissez parmi plus de 4000 modèles HTML ou Flash</li>
              <li>
                Consultation individuelle sur la conception graphique de votre
                site
              </li>
              <li>
                Services de marketing par moteur de recherche et de conception
                graphique pour le cybercommerce
              </li>
            </ul>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                minWidth={"200px"}
                margin={"10px 0"}
                url="/developpez/services-web/conception-de-sites-web/construction-de-votre-site-web/"
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2>Concevez vous-même votre site web</h2>
            <h3>Autoconception Web avec EasySiteWizard Pro</h3>
            <ul>
              <li>
                Un assistant de conception convivial (avec glisser-déposer)
              </li>
              <li>
                Choix parmi des centaines de modèles de conception
                professionnelle
              </li>
              <li>
                Profitez de notre iconothèque de plus de 30 000 images ou
                ajoutez les vôtres
              </li>
            </ul>
            <div className={styles.button_wrapper}>
              <Button
                text={"D'INFO"}
                url="/developpez/services-web/conception-de-sites-web/easysitewizard-pro/"
                minWidth={"200px"}
                margin={"10px 0"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
